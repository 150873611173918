<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper full">
      <image-selector
        :defaultImage="user.profile_image"
        ressource_name="users"
        :ressource_id="user.id ? user.id : 0"
        field="profile_image"
        @imageChanged="
          (file_url) => {
            user.profile_image = file_url;
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.attachment" />
    </div>

    <div
      class="form-wrapper full"
      v-if="showIsStaff && $currentUser().is_staff"
    >
      <base-checkbox
        v-model="user.is_staff"
        @change="
          () => {
            onFormChanged();
            user.organization.id = null;
            user.reseller.id = null;
          }
        "
      >
        <span class="form-control-label">
          {{ $t("USERS.IS_STAFF") }}
          <span class="check">
            <i class="far fa-check"></i>
          </span>
        </span>
      </base-checkbox>
      <validation-error :errors="apiValidationErrors.is_staff" />
    </div>

    <div
      class="form-wrapper full"
      v-if="user.organization ? user.organization.id : null"
      @change="
        () => {
          onFormChanged();
        }
      "
    >
      <base-checkbox v-model="user.restrict_to_locations">
        <span class="form-control-label">
          {{ $t("USERS.RESTRICT_TO_LOCATIONS") }}
          <span class="check">
            <i class="far fa-check"></i>
          </span>
        </span>
      </base-checkbox>
      <validation-error :errors="apiValidationErrors.restrict_to_locations" />
    </div>

    <div
      class="form-wrapper"
      v-if="
        !user.is_staff &&
        showReseller &&
        $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
      "
    >
      <base-input
        :label="$t('COMMON.RESELLER')"
        :placeholder="$t('COMMON.RESELLER')"
      >
        <reseller-selector
          :reseller="user.reseller ? user.reseller.id : null"
          :filterable="true"
          :showAll="false"
          :allowNone="true"
          @resellerChanged="
            (resellerId) => {
              user.reseller = { type: 'resellers', id: resellerId };
              user.organization = { type: 'organizations', id: null };
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.reseller" />
    </div>

    <div
      class="form-wrapper"
      v-if="
        !user.is_staff &&
        showOrganization &&
        $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
      "
    >
      <base-input
        :label="$t('COMMON.ORGANIZATION')"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :filterReseller="user.reseller ? user.reseller.id : null"
          :organization="user.organization ? user.organization.id : null"
          :filterable="true"
          :showAll="false"
          :allowNone="true"
          @organizationChanged="
            (organizationId) => {
              user.organization = { type: 'organizations', id: organizationId };
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>

    <div
      class="form-wrapper"
      v-if="
        user.restrict_to_locations &&
        $currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)
      "
    >
      <base-input
        :label="`${$t('COMMON.LOCATION')}`"
        :placeholder="$t('COMMON.LOCATION')"
      >
        <locations-selector
          :locations="user.allowedLocations"
          :filterable="true"
          :showAll="false"
          :multiple="true"
          :organization="user.organization ? user.organization.id : null"
          @locationsChanged="
            (locations) => {
              user.allowedLocations = locations;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.location" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.FIRSTNAME')} (*)`"
        :placeholder="$t('COMMON.FIRSTNAME')"
        v-model="user.firstname"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.firstname" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.LASTNAME')} (*)`"
        :placeholder="$t('COMMON.LASTNAME')"
        v-model="user.lastname"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.lastname" />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.EMAIL')} (*)`"
        :placeholder="$t('COMMON.EMAIL')"
        v-model="user.email"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.email" />
    </div>

    <!-- Phone number -->
    <div class="form-wrapper full">
      <div class="row equal">
        <div class="equal-item type">
          <base-input :label="`${$t('COMMON.TYPE')}`">
            <el-select
              :label="$t('COMMON.TYPE')"
              :placeholder="$t('COMMON.TYPE')"
              v-model="user.phone_type"
              @change="
                (type) => {
                  user.phone_type = type;
                  onFormChanged();
                }
              "
            >
              <el-option
                v-for="(value, key) in phoneTypesOptions"
                :key="key"
                :value="value"
                :label="$t(`COMMON.PHONE_TYPE_${value}`)"
              />
            </el-select>
          </base-input>
        </div>
        <div class="equal-item phone">
          <base-input :label="`${$t('COMMON.PHONE')}`">
            <phone-number-input
              :phoneNumber="user.phone"
              @phoneNumberChanged="
                (phone) => {
                  user.phone = phone;
                  onFormChanged();
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.phone" />
        </div>
        <div class="equal-item extension">
          <base-input
            :label="`${$t('COMMON.EXTENSION')}`"
            v-model="user.phone_extension"
            @change="
              () => {
                onFormChanged();
              }
            "
            :inputClasses="'extension-input'"
          />
          <validation-error :errors="apiValidationErrors.phone_extension" />
        </div>
      </div>
    </div>

    <!-- Other phone numbers -->
    <div class="form-wrapper full">
      <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
        <phone-numbers-selector
          :phoneNumbers="user.other_phones"
          @phoneNumbersChanged="
            (phoneNumbers) => {
              user.other_phones = phoneNumbers;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.other_phones" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.ROLE')} (*)`"
        :placeholder="$t('COMMON.ROLE')"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
      >
        <role-selector
          :role="user.roles.length ? user.roles[0].id : null"
          :filterable="true"
          :showAll="false"
          :filterReseller="user.reseller ? user.reseller.id : null"
          :filterOrganization="user.organization ? user.organization.id : null"
          @roleChanged="
            (roleId) => {
              user.roles[0].id = roleId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.roles" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('COMMON.LOCALE')} (*)`"
        :placeholder="$t('COMMON.LOCALE')"
      >
        <locale-selector
          :locale="user.locale"
          @localeChanged="
            (locale) => {
              user.locale = locale;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.locale" />
    </div>

    <div class="form-wrapper">
      <base-input
        v-model="user.password"
        type="password"
        name="new_password"
        prepend-icon="fa fa-key"
        :placeholder="`${$t('COMMON.PASSWORD')} ${user.id ? '' : '(*)'}`"
        :label="`${$t('COMMON.PASSWORD')} ${user.id ? '' : '(*)'}`"
        @change="
          () => {
            onFormChanged();
          }
        "
        @focus="showPasswordHint = true"
        @blur="showPasswordHint = false"
      />
      <div class="mb-4" v-if="showPasswordHint">
        <small class="text-muted font-weight-bolder">
          {{ $t("USERS.PASSWORD_COMPLEXITY") }}
        </small>
      </div>
      <validation-error :errors="apiValidationErrors.password" />
    </div>

    <div class="form-wrapper">
      <base-input
        v-model="user.password_confirmation"
        type="password"
        name="confirm_password"
        autocomplete="on"
        prepend-icon="fa fa-key"
        :placeholder="`${$t('COMMON.CONFIRM_PASSWORD')} ${
          user.id ? '' : '(*)'
        }`"
        :label="`${$t('COMMON.CONFIRM_PASSWORD')} ${user.id ? '' : '(*)'}`"
        @change="
          () => {
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.password_confirmation" />
    </div>

    <div class="col-12 mt-2 mb-3" style="display: none">
      <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
    </div>

    <billing-informations-form
      style="display: none"
      :billingInformationsData="user"
      :formErrors="formErrors"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{ user.id ? $t("USERS.EDIT_USER") : $t("USERS.ADD_USER") }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import { phoneTypesOptions } from "@/constants/common";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ImageSelector from "@/components/ImageSelector.vue";
import RoleSelector from "@/components/RoleSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import ResellerSelector from "@/components/ResellerSelector.vue";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import LocaleSelector from "@/components/LocaleSelector.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    ImageSelector,
    RoleSelector,
    OrganizationSelector,
    ResellerSelector,
    BaseCheckbox,
    LocaleSelector,
    BillingInformationsForm,
    PhoneNumberInput,
    LocationsSelector,
    PhoneNumbersSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    userData: {
      type: Object,
    },
    formErrors: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showReseller: {
      type: Boolean,
      default: true,
    },
    showOrganization: {
      type: Boolean,
      default: true,
    },
    showIsStaff: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    let userData = { ...this.userData };
    userData = this.$fillUserOrganizationData(userData);
    return {
      user: userData,
      phoneTypesOptions,
      showPasswordHint: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      let userData = cloneDeep(this.user);
      userData = this.$fillUserOrganizationData(userData);
      if (userData.roles[0]) {
        if (!userData.roles[0].id) {
          delete userData.roles[0].id;
        }
      }
      if (userData.organization) {
        if (!userData.organization.id) {
          delete userData.organization;
        }
      }
      if (userData.reseller) {
        if (!userData.reseller.id) {
          delete userData.reseller;
        }
      }
      if (!userData.password && userData?.id) {
        delete userData.password;
        delete userData.password_confirmation;
      }

      this.$emit("userSubmitted", userData);
    },

    billingInformationsChanged(billingInformations) {
      this.user = { ...this.user, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    userData(userData) {
      if (userData) {
        this.user = cloneDeep(userData);
        if (!this.user.organization) {
          this.user.organization = {
            type: "organizations",
            id: null,
          };
        }
      }
    },
  },
};
</script>
